@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.container {
  position: relative;
  &-header {
    width: 138px;
    max-height: 42px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 3px;
    border: 2px solid var(--cst-drop-listsort-outline-default);
    background: var(--cst-drop-listsort-background-default);
    border-radius: var(--cornerradius10);

    &.hidden-title {
      width: 66px;
      height: 38px;
      padding: 8px 10px 8px 8px;
    }

    @include min-428-break {
      width: 160px;
      height: 46px;
      padding: 7px 10px 7px 7px;

      &.hidden-title {
        width: 76px;
        min-height: 48px;
        padding: 8px 10px 8px 8px;
      }
    }

    @include min-744-break {
      width: 160px;
      height: 46px;
      padding: 7px 10px 7px 7px;

      &.hidden-title {
        width: 160px;
        min-height: 48px;
        padding: 8px 10px 8px 8px;
      }
    }
    @include min-1024-break {
      width: 113px;
      max-height: 32px;
      padding: 5px;
    }
    @include min-1440-break {
      width: 160px;
      max-height: 46px;
      padding: 5px 10px;
    }

    &:hover {
      border: 2px solid var(--cst-drop-listsort-outline-hover);
      background: var(--cst-drop-listsort-background-hover);
      & p {
        color: var(--cst-drop-listsort-text-hover);
      }
    }
    &-opened {
      pointer-events: none;
    }
  }
}

.sheet {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 14px;

  @include min-428-break {
    min-width: 428px;
    gap: 14px;
  }

  &-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    @include min-428-break {
      gap: 10px;
    }
  }
}

.game_item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;

  &.hidden-title {
    img {
      @include singleFixedSize(22px);
    }
  }

  @include min-428-break {
    &.hidden-title {
      img {
        @include singleFixedSize(32px);
      }
    }
  }

  @include min-1440-break {
    padding: 12px;
  }

  p[id='list-item-title'] {
    color: var(--cst-drop-listsort-text-default);
    @include typography-s;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-base;
    }

    @include min-1024-break {
      @include typography-xs;
    }

    @include min-1440-break {
      @include typography-base;
    }
  }
}

.drop_down {
  width: 100%;
  margin-top: 6px;
  box-shadow: var(--elevation-1);
  outline: 2px solid var(--cst-drop-listgame-outline);
  outline-offset: -2px;
  background: var(--cst-drop-listgame-background);
  border-radius: var(--cornerradius6);
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 1;

  @include min-1440-break {
    border-radius: var(--cornerradius10);
  }
}

.icon {
  transition: all 0.2s;
  @include singleFixedSize(14px);

  &.hidden-title {
    @include singleFixedSize(18px);
  }
  @include min-1440-break {
    @include singleFixedSize(18px);
  }

  &-active {
    transform: rotate(180deg);
  }
}

.button {
  @include buttonSizes('38', '46', '46', '42', '50', '50');
}

.no_padding {
  padding: 0;
  pointer-events: none;
}
